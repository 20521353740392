import React, { useState, useEffect } from "react";
import "./GalleryGrid.css";

import { Galleria } from "primereact/galleria";
import { PhotoService } from "../../Services/photoService";

interface GalleryProps {
    dir: string;
}

const GalleryGrid = (props: GalleryProps) => {
    const [images, setImages] = useState(null);
    const galleriaService = new PhotoService();

    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 5,
        },
        {
            breakpoint: "768px",
            numVisible: 3,
        },
        {
            breakpoint: "560px",
            numVisible: 1,
        },
    ];

    useEffect(() => {
        try {
            let a = [];

            if (props.dir === "cosplays") {
                a = galleriaService.getImages((require as any).context(`../../../public/images/cosplays/`, false, /\.(png|jpe?g|svg)$/));
            } else if (props.dir === "cosplay_plans") {
                a = galleriaService.getImages((require as any).context(`../../../public/images/cosplay_plans/`, false, /\.(png|jpe?g|svg)$/));
            }

            setImages(a);
        } catch (error) {
            console.log("No image found");
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const itemTemplate = (item: string) => {
        return (
            <img
                src={item}
                onError={(e) => ((e.target as any).src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                alt={item}
                style={{ width: "100%", display: "block" }}
            />
        );
    };
    const caption = (item: string) => {
        return (
            <React.Fragment>
                <h4 className="mb-2">TITLE</h4>
                <p>Desc</p>
            </React.Fragment>
        );
    };

    return (
        <div className="GalleryGrid">
            <div className="card">
                <Galleria
                    value={images!}
                    responsiveOptions={responsiveOptions}
                    numVisible={5}
                    style={{ maxWidth: "640px" }}
                    showItemNavigators
                    showThumbnails={false}
                    item={itemTemplate}
                    circular
                    autoPlay
                    transitionInterval={2500}
                    caption={caption}
                />
            </div>
        </div>
    );
};

export default GalleryGrid;
