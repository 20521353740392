import React from "react";
import { useState, useEffect } from "react";
import "./Menu.css";

import { TabMenu } from "primereact/tabmenu";
import { Menubar } from "primereact/menubar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

const Menu = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const items = [
        { label: "Home", icon: <FontAwesomeIcon icon={solid("home")} /> },
        { label: "Cosplays", icon: <FontAwesomeIcon icon={solid("camera")} /> },
        { label: "Cosplay plans", icon: <FontAwesomeIcon icon={solid("image")} /> },
        { label: "Webshop", icon: <FontAwesomeIcon icon={solid("store")} /> },
    ];

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="Menu">
            {windowDimensions.width > 600 ? <TabMenu className="TabMenu" model={items} /> : <Menubar className="Menu" model={items} />}
        </div>
    );
};

export default Menu;
