import React from "react";
import "./ShopGrid.css";

const ShopGrid = () => {
    fetch("https://ko-fi.com/roromiyaruka/shop").then((html) => console.log(html.json()));

    return <div className="ShopGrid"></div>;
};

export default ShopGrid;
