import React from "react";
import "./App.css";

import Menu from "../../Components/Menu/Menu";
import Grid from "../../Components/Grid/Grid";
import LinkGrid from "../../Components/LinkGrid/LinkGrid";
import GalleryGrid from "../../Components/GalleryGrid/GalleryGrid";
import ShopGrid from "../../Components/ShopGrid/ShopGrid";

const App = () => {
    (window as any).kofiWidgetOverlay.draw("yennekoo", {
        type: "floating-chat",
        "floating-chat.donateButton.text": "Support Me",
        "floating-chat.donateButton.background-color": "#cc7e85",
        "floating-chat.donateButton.text-color": "#fff",
    });

    return (
        <div className="App">
            <Menu />
            <Grid>
                <LinkGrid></LinkGrid>
            </Grid>
            <Grid>
                <GalleryGrid dir="cosplays" />
            </Grid>
            <Grid>
                <GalleryGrid dir="cosplay_plans" />
            </Grid>
            <Grid>
                <ShopGrid />
            </Grid>
        </div>
    );
};

export default App;
