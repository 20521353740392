import React from "react";
import "./Grid.css";

interface MyProps {
    children?: React.ReactNode;
}

const Grid = (props: MyProps) => {
    return <div className="Grid">{props.children}</div>;
};

export default Grid;
