import React from "react";
import "./LinkGrid.css";

import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";

const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
};

const LinkGrid = () => {
    return (
        <div className="LinkGrid">
            <div>
                <Avatar
                    className="avatar"
                    image="https://d1fdloi71mui9q.cloudfront.net/t7Mi4LOyQ3CiMkho5V1C_AmwPfTwl8dLWTuwK"
                    shape="circle"
                    size="xlarge"
                />
            </div>
            <h1>Yennekoo</h1>
            <img className="logos" alt="kofi_icon" src="/icons/kofi.png" onClick={() => openInNewTab("https://ko-fi.com/yennekoo")} />
            <img className="logos" alt="insta_icon" src="/icons/instagram.svg" onClick={() => openInNewTab("https://www.instagram.com/yennekoo/")} />
            <img className="logos" alt="tiktok_icon" src="/icons/tiktok.png" onClick={() => openInNewTab("https://www.tiktok.com/@yennekoo")} />
            <img className="logos" alt="reddit_icon" src="/icons/reddit.png" onClick={() => openInNewTab("https://www.reddit.com/user/Yennekoo")} />
            <br />
            <Button className="button p-button-rounded" onClick={() => openInNewTab("https://ko-fi.com/yennekoo")} label="Ko-fi" />
            <br />
            <Button className="button p-button-rounded" onClick={() => openInNewTab("https://www.instagram.com/yennekoo/")} label="Instagram" />
            <br />
            <Button className="button p-button-rounded" onClick={() => openInNewTab("https://www.tiktok.com/@yennekoo")} label="TikTok" />
            <br />
            <Button className="button p-button-rounded" onClick={() => openInNewTab("https://www.reddit.com/user/Yennekoo")} label="Reddit" />
            <br />
            <Button className="button p-button-rounded" onClick={() => openInNewTab("https://thronegifts.com/u/yennekoo")} label="Throne Gifts" />
            <br />
            <Button
                className="button p-button-rounded"
                onClick={() => openInNewTab("https://v-christian4.webnode.hu/l/yennekoo/")}
                label="Interview"
            />
            <br />
        </div>
    );
};

export default LinkGrid;
